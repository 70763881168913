import React from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"

import Layout from "../components/layout"
import * as style from "./article.module.css"

const article = ({ data }) => (
    <Layout>
    <article className={style.article}>
      <h1 className={style.article__title}>{data.markdownRemark.frontmatter.title}</h1>

      <div className={style.article__meta}>
        by {data.markdownRemark.frontmatter.author}. Published{" "}
        {new Date(data.markdownRemark.frontmatter.date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}{" "}
      </div>
      <div className={style.article__tax}>
        Filed under:{" "}
        {data.markdownRemark.frontmatter.tags.map((tags, index) => [
          index > 0 && ", ",
          <Link key={index} to={`/tags/${_.kebabCase(tags)}`}>
            {tags}
          </Link>,
        ])}
      </div>
      <div
        className={style.article__content}
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />
    </article>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        author
      }
    }
  }
`
export default article